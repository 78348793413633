<template>
  <div>
    <h1 class="title is-4">Alterar Senha</h1>

    <form @keyup.enter="changePassword()">
      <b-field grouped class="column-direction-touch">
        <b-field
          expanded
          label="Senha Atual"
          :type="campoInvalido('currentPassword', 'data') ? 'is-danger' : ''"
          :message="
            campoInvalido('currentPassword', 'data') ? 'Senha Atual é um campo obrigatório' : ''
          "
        >
          <b-input
            password-reveal
            placeholder="Senha Atual"
            type="password"
            v-model="$v.data.currentPassword.$model"
          />
        </b-field>

        <b-field
          expanded
          class="new-password-field"
          label="Nova Senha"
          :type="campoInvalido('newPassword', 'data') ? 'is-danger' : ''"
          :message="campoInvalido('newPassword', 'data') ? 'Nova senha é um campo obrigatório' : ''"
        >
          <b-input
            password-reveal
            placeholder="Nova Senha"
            type="password"
            v-model="$v.data.newPassword.$model"
          />

          <b-notification
            has-icon
            aria-close-label="Close notification"
            class="custom-notification"
            role="alert"
            type="is-danger"
            v-if="data.newPassword && passwordValidation.errors.length"
            :closable="false"
          >
            <li v-for="error in passwordValidation.errors" :key="error.id">
              <b-icon icon="arrow-right-bold-outline" size="is-small"></b-icon>
              {{ error.message }}
            </li>
          </b-notification>
        </b-field>

        <b-field
          expanded
          password-reveal
          label="Confirmar Nova Senha"
          :type="campoInvalido('confirmNewPassword', 'data') ? 'is-danger' : ''"
          :message="
            campoInvalido('confirmNewPassword', 'data') ? 'As novas senhas devem ser idênticas' : ''
          "
        >
          <b-input
            password-reveal
            placeholder="Confirmar Nova Senha"
            type="password"
            v-model="$v.data.confirmNewPassword.$model"
          />
        </b-field>

        <b-field>
          <b-button
            class="button is-primary is-fullwidth change-password-button"
            :loading="loading"
            @click.prevent.stop="changePassword()"
          >
            Alterar Senha
          </b-button>
        </b-field>
      </b-field>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { required, sameAs } from 'vuelidate/lib/validators';

import formValidation from '@/mixins/formulario';
import { passwordRules } from '@/utils/data';

export default {
  name: 'ChangePassword',
  mixins: [formValidation],
  data() {
    const data = {
      confirmNewPassword: '',
      currentPassword: '',
      newPassword: '',
    };

    return {
      data,
      loading: false,
      rules: [...passwordRules],
    };
  },
  validations: {
    data: {
      confirmNewPassword: {
        required,
        sameAsPassword: sameAs('newPassword'),
      },
      currentPassword: { required },
      newPassword: { required },
    },
  },
  async beforeRouteLeave(to, from, next) {
    if (this.logged && this.$v.data.$anyDirty) {
      const exit = await this.$confirmacao({
        message: 'Os dados serão perdidos. Tem certeza que deseja continuar?',
      });
      return next(exit);
    }
    return next();
  },
  computed: {
    ...mapGetters({ logged: 'getLogged' }),
    passwordValidation() {
      const password = this.data.newPassword;

      const errors = [];
      this.rules.forEach(condition => {
        if (!condition.regex.test(password)) {
          errors.push(condition);
        }
      });

      if (!errors.length) {
        return { valid: true, errors };
      }

      return { valid: false, errors };
    },
  },
  methods: {
    ...mapActions(['updateUser']),
    cleanPasswordFields() {
      this.data.confirmNewPassword = '';
      this.data.currentPassword = '';
      this.data.newPassword = '';
      this.$v.data.$reset();
    },
    async changePassword() {
      if (this.$v.data.$invalid || this.passwordValidation.length) {
        this.$v.data.$touch();
        return;
      }

      this.loading = true;
      try {
        await this.updateUser(this.data);
        this.$alerta('Senha alterada', 'is-success');
        this.cleanPasswordFields();
      } catch (error) {
        if (error.response.data.error.type === 'ValidationError') {
          this.$alerta('Senha inválida', 'is-danger');
        } else {
          this.$alerta('Senha atual incorreta', 'is-danger');
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-notification {
  margin-top: 1rem;
}

.new-password-field {
  display: flex;
  flex-direction: column;
  max-width: 30%;
}

.change-password-button {
  height: 100px;
}

@media (max-width: 769px) {
  .custom-notification {
    font-size: 0.92rem;
    padding: 0.5rem;
  }

  .new-password-field {
    max-width: unset;
  }

  .change-password-button {
    height: unset;
    margin-top: 0.75rem;
  }
}
</style>
